import { render, staticRenderFns } from "./Cards.vue?vue&type=template&id=60817208&scoped=true"
import script from "./Cards.vue?vue&type=script&lang=ts"
export * from "./Cards.vue?vue&type=script&lang=ts"
import style0 from "./Cards.vue?vue&type=style&index=0&id=60817208&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@4.3.0_lodash@4.17.21_prettier@2.8.8_react-dom@18.2.0_react@17.0_rptp5zt4ymie7pjpi4t5fdjf2y/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60817208",
  null
  
)

export default component.exports