
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class OfferCardLayout extends Vue {
  @Prop({
    required: false,
    default: '',
    type: String,
  })
  readonly borderColor!: string

  @Prop({
    required: true,
    default: '',
    type: String,
  })
  readonly url!: string

  @Prop({ required: false, type: Boolean, default: true })
  clickable!: boolean

  get layoutStyles () {
    return {
      borderColor: this.borderColor ? this.borderColor : 'var(--border-color)',
    }
  }

  @Emit('click')
  onClick () {
    return true
  }
}
