
import { Component, Prop, Vue } from 'nuxt-property-decorator'

import type { Theme } from '~/types/theme'

@Component
export default class HowItWorksCardsItem extends Vue {
  @Prop({ required: true })
  readonly index!: number

  @Prop({ required: false, default: false })
  readonly isActive!: boolean

  @Prop({ default: 'light', type: String })
  readonly theme!: Theme
}
