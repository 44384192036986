var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _vm._m(0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'hiw-cards__item',
    `hiw-cards__item--theme_${_vm.theme}`
  ]},[_c('span',{class:[
      'hiw-cards__index',
      `hiw-cards__index--theme_${_vm.theme}`,
      {
        'hiw-cards__index--active': _vm.isActive
      }
    ]},[_vm._v(_vm._s(_vm.index))]),_vm._v(" "),_c('div',{class:[
      'hiw-card',
      `hiw-card--theme_${_vm.theme}`
    ]},[_vm._t("default")],2)])
}]

export { render, staticRenderFns }