
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IOfferOption, OfferProvider } from '@alao-frontend/core'
import OfferCardDetailsTop from '~/components/offers/OfferCard/OfferCardDetails/OfferCardDetailsTop.vue'
import OfferCardDetailsContent from '~/components/offers/OfferCard/OfferCardDetails/OfferCardDetailsContent.vue'
import OfferCardDetailsBottom from '~/components/offers/OfferCard/OfferCardDetails/OfferCardDetailsBottom.vue'
import AppProviderRating from '~/components/AppProviderRating/AppProviderRating.vue'
import OfferMainDetails from '~/components/offers/OfferMainDetails'
import OfferOptions from '~/components/offers/OfferOptions'
import OfferCardExclusiveLabel from '~/components/offer-card/OfferCardExclusiveLabel.vue'
import OfferBenefitBadge from '~/components/offers/benefits/OfferBenefitBadge.vue'
import { FeatureFlags } from '~/core/config/feature-flags'
@Component({
  components: {
    OfferCardExclusiveLabel,
    OfferCardDetailsTop,
    OfferCardDetailsContent,
    OfferCardDetailsBottom,
    AppProviderRating,
    OfferMainDetails,
    OfferOptions,
    OfferBenefitBadge,
  },
})
export default class OfferCardDetails extends Vue {
  @Prop({ required: false, default: '', type: String })
  readonly title!: string

  @Prop({ required: true, default: () => ({}), type: Object })
  readonly provider!: OfferProvider

  @Prop({ required: true, default: () => [], type: Array })
  readonly mainOptions!: IOfferOption[]

  @Prop({ required: true, default: () => [], type: Array })
  readonly detailOptions!: IOfferOption[]

  @Prop({ required: true, default: () => [], type: Array })
  readonly benefitOptions!: IOfferOption[]

  @Prop({ required: true, default: '', type: String })
  readonly slug!: string

  @Prop({ type: String, required: false, default: '' })
  readonly prependText!: string

  @Prop({ default: 0, type: Number })
  readonly progressBar!: number | null

  @Prop({ default: null, type: String })
  readonly endDateInFewDays!: string | null

  @Prop({ default: false, type: Boolean })
  readonly isAlaoExclusive!: boolean

  featureBlackFriday = this.$featureFlags.isEnabled(FeatureFlags.BLACK_FRIDAY_PROMO)
  featureAmpComparator = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR)
}
